import React from "react";

export const Menu = () => {
    return (
        <div className="relative w-[39px] h-[48px] mt-0">
            <img
                alt="Icon"
                src="/menu icon.png"
                className="absolute w-[33px] h-[28px] left-[30px] top-2"
            />
        </div>
    );
};
