import React, {useEffect, useState }   from "react";
import { Header } from "../components/Header";

export const ExpenseOverview = () => {
    const [isAddExpenseModalOpen, setAddExpenseIsModalOpen] = useState(false);
    const [isSettleBalanceModalOpen, setSettleBalanceIsModalOpen] = useState(false);

    const [selectedGroupMembers, setSelectedGroupMembers] = useState({});
    const [groups, setGroups] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [totalUserOwes, setTotalUserOwes] = useState(0); // For "YOU CURRENTLY OWE"
    const [totalUserIsOwed, setTotalUserIsOwed] = useState(0); // For "YOU ARE OWED"

    const [debts, setDebts] = useState([]);
    const [credits, setCredits] = useState([]);

    const [totalDebt, setTotalDebt] = useState(0);

    const addDebt = (group, amountOwed) => {
        setDebts(prevDebts => {
            // Check if the debt already exists to avoid duplicates
            const exists = prevDebts.some(debt => debt[0] === group);
            if (!exists) {
                return [...prevDebts, [group, amountOwed]];
            }
            return prevDebts; // Return existing debts if no addition is needed
        });
    };

    const addCredit = (group, amountOwed) => {
        setCredits(prevCredits => {
            // Check if the credit already exists to avoid duplicates
            const exists = prevCredits.some(credit => credit[0] === group);
            if (!exists) {
                return [...prevCredits, [group, amountOwed]];
            }
            return prevCredits; // Return existing credits if no addition is needed
        });
    };

    useEffect(() => {
        const fetchGroupsAndMembers = async () => {
            setLoading(true);
            try {
                const token = localStorage.getItem('idToken');
                const userId = localStorage.getItem('id');

                // Fetch the total amount owed
                const debtResponse = await fetch(`http://localhost:8080/api/expenses/getNetDebt?userId=${userId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (debtResponse.ok) {
                    const debtAmount = await debtResponse.json();
                    setTotalUserOwes(debtAmount);
                }

                // Fetch the total amount owed to user
                const owedResponse = await fetch(`http://localhost:8080/api/expenses/getNetOwed?userId=${userId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (owedResponse.ok) {
                    const owedAmount = await owedResponse.json();
                    setTotalUserIsOwed(owedAmount);
                }

                // Fetch groups the user is in
                const groupResponse = await fetch(`http://localhost:8080/api/usergroups/user?userId=${userId}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (!groupResponse.ok) {
                    throw new Error('Failed to fetch groups');
                }

                const groupsData1 = await groupResponse.json();
                const groupsData = groupsData1.reverse(); // Reverse the order
                const limitedGroups = groupsData.slice(0, 3); // Limit to 3 groups
                setGroups(limitedGroups);

                // Fetch group members and debts for the limited groups
                const groupMembersPromises = limitedGroups.map(async (group) => {
                    const membersResponse = await fetch(`http://localhost:8080/api/usergroups/group?groupId=${group.id}`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        }
                    });

                    if (!membersResponse.ok) {
                        throw new Error(`Failed to fetch members for group ${group.group_name}`);
                    }

                    const members = await membersResponse.json();
                    return { groupId: group.id, members: members.map(member => member.username) };
                });

                const groupMembersData = await Promise.all(groupMembersPromises);

                const membersMap = {};
                groupMembersData.forEach(({ groupId, members }) => {
                    membersMap[groupId] = members;
                });

                setSelectedGroupMembers(membersMap);

                // Calculate total amount owed by user and total user is owed
                await calculateUserDebts(limitedGroups, userId, token);

            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchGroupsAndMembers();
    }, []);

    const calculateUserDebts = async (groups, userId, token) => {
        let userOwesTotal = 0;

        for (const group of groups) {
            // Fetch how much the user owes in the group
            const owesResponse = await fetch(`http://localhost:8080/api/usergroups/get?userId=${userId}&groupId=${group.id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (owesResponse.ok) {
                const amountOwed = await owesResponse.json();
                userOwesTotal += amountOwed; // Add to the total the user owes
                if (amountOwed > 0) {
                    addDebt(group.group_name, amountOwed);
                }
            }
            setTotalDebt(userOwesTotal);
        }
    };

    const toggleAddExpenseModal = () => {
        setAddExpenseIsModalOpen(!isAddExpenseModalOpen);
    };

    const toggleSettleBalanceModal = () => {
        setSettleBalanceIsModalOpen(!isSettleBalanceModalOpen);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="bg-[#f6f6f6] min-h-screen flex flex-col items-center">
            {/* Header */}
            <Header className="w-full" />

            {/* Wrapper with max-width and padding */}
            <div className="w-full max-w-screen-xl px-4 "> {/* Ensure margin to avoid overlap */}
                {/* Expense Overview Section */}
                <div className="mt-4 md:mt-16 p-4 md:p-8 bg-white rounded-2xl shadow-lg flex flex-col items-center space-y-8">
                    {/* Debt and Credit */}
                    <div className="flex flex-col md:flex-row justify-between items-center w-full space-y-8 md:space-y-0">
                        <div className="text-center">
                            <div className="text-1xl font-semibold text-[#262526] ml-4 md:ml-8 lg:ml-40">YOUR DEBT</div>
                            <div className="text-7xl md:text-7xl text-[#d50808] font-mono ml-4 md:ml-8 lg:ml-40">R{totalUserOwes}</div>
                        </div>                        
                        <div className="text-center">
                            <div className="text-1xl font-semibold text-[#262526] mr-4 md:mr-8 lg:mr-40">YOUR CREDIT</div>
                            <div className="text-7xl md:text-7xl text-[#0bc20b] font-mono mr-4 md:mr-8 lg:mr-40">R{totalUserIsOwed}</div>
                        </div>
                    </div>

                    {/* debts */}
                    <div className="flex flex-col w-full space-y-4">
                        {debts.map((debt, index) => {
                            const debtRatio = (debt[1]/totalDebt);

                            return (
                                <div key={index} className="flex items-center space-x-4">
                                    <div className="flex flex-col items-start min-w-[120px]">
                                        <span className="text-sm md:text-lg font-mono">R{debt[1]}</span> {/* Debt amount */}
                                        <div className="text-xs text-[#5b5b5b] font-sans">{debt[0]}</div>          {/* Group */}
                                    </div>
                                    <div 
                                        className="flex-shrink-0 h-4 bg-[#d50808] rounded-full w-40 md:w-60"
                                        style={{
                                            width: `calc(${debtRatio * 300}px)`,         // Mobile width
                                            '@media (min-width: 768px)': {                         // Tailwind's md: breakpoint for 768px+
                                              width: `calc(${debtRatio * 50}px)`       // Desktop width
                                            }
                                          }}
                                    ></div> {/* Bar width */}
                                </div>
                            )
                        })}
                    </div>

                    {/* credits */}
                    <div className="flex flex-col w-full space-y-4">
                        {credits.map((credit, index) => (
                            <div key={index} className="flex items-center space-x-4">
                                <div className="flex flex-col items-start min-w-[120px]">
                                    <span className="text-sm md:text-lg font-mono">R{credit[1]}</span> {/* Credit amount */}
                                    <div className="text-xs text-[#5b5b5b]">{credit[0]}</div>          {/* Group */}
                                </div>
                                <div className={"flex-shrink-0 h-4 bg-[#d50808] rounded-full w-40 md:w-60"}></div> {/* Bar width */}
                            </div>
                        ))}
                    </div>

                    {/* Balance */}
                    <div className="w-full bg-gray-300 text-center p-6 rounded-lg">
                        <div className="text-lg md:text-2xl">you have a balance of</div>
                        <div className="text-3xl md:text-5xl font-mono text-[#262526] underline">{totalUserIsOwed-totalUserOwes}</div>
                    </div>

                    {/* Action Buttons */}
                    {/* <div className="flex space-x-4 mt-8">
                        <button 
                            className="px-6 py-3 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-[#eae2f8]"
                            onClick={toggleAddExpenseModal}
                        >
                            ADD EXPENSE
                        </button>
                        <button 
                            className="px-6 py-3 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-[#eae2f8]"
                            onClick={toggleSettleBalanceModal}
                        >
                            SETTLE BALANCE
                        </button>
                    </div> */}
                </div>
            </div>

            { /* Add Expense Model*/}
            {isAddExpenseModalOpen && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 backdrop-filter backdrop-blur-sm"
                    onClick={toggleAddExpenseModal}
                >
                    <div className="bg-[#eae2f8] rounded-lg p-6 max-w-lg w-full relative" onClick={(e) => e.stopPropagation()}>
                        {/* Modal Form */}
                        <h2 className="text-2xl font-semibold mb-4">Add Expense</h2>
                        <div className="grid grid-cols-2 gap-4 mb-4">
                            {/* Form Fields */}
                            <div>
                                <label className="block text-sm font-medium">Type *</label>
                                <select className="mt-1 block w-full border border-gray-300 rounded-md">
                                    <option>SELECT</option>
                                    <option>Rent</option>
                                    <option>Utilities</option>
                                    <option>Groceries</option>
                                    <option>Travel</option>
                                    <option>Dining Out</option>
                                    <option>Entertainment</option>
                                    <option>Transportation</option>
                                    <option>Household Supplies</option>
                                    <option>Internet</option>
                                    <option>Petrol</option>
                                    <option>Insurance</option>
                                    <option>Repairs</option>
                                    <option>Gifts</option>
                                    <option>Sundry</option>
                                </select>
                            </div>
                            <div>
                                <label className="block text-sm font-medium">Amount *</label>
                                <input type="text" className="mt-1 block w-full border border-gray-300 rounded-md" placeholder="R" />
                            </div>
                            <div>
                                <label className="block text-sm font-medium">Paid by *</label>
                                <select className="mt-1 block w-full border border-gray-300 rounded-md">
                                    <option>SELECT</option>
                                    {/* Add more options here */}
                                </select>
                            </div>
                            <div>
                                <label className="block text-sm font-medium">From group *</label>
                                <select className="mt-1 block w-full border border-gray-300 rounded-md">
                                    <option>SELECT</option>
                                    {/* Add more options here */}
                                </select>
                            </div>
                            <div>
                                <label className="block text-sm font-medium">Split method *</label>
                                <select className="mt-1 block w-full border border-gray-300  rounded-md">
                                    <option>SELECT</option>
                                    {/* Add more options here */}
                                </select>
                            </div>
                            <div>
                                <label className="block text-sm font-medium">Upload receipt</label>
                                <button className="mt-1 block w-full border border-gray-300 rounded-md px-4 py-2">OPEN FILE</button>
                            </div>
                        </div>

                        {/* Description */}
                        <div className="mb-4">
                            <label className="block text-sm font-medium">Description</label>
                            <textarea className="mt-1 block w-full border border-gray-300 rounded-md"></textarea>
                        </div>

                        {/* Modal Buttons */}
                        <div className="flex justify-end space-x-4">
                            <button className="px-4 py-2 bg-black text-white rounded-md">SAVE</button>
                            <button className="px-4 py-2 bg-gray-300 text-black rounded-md" onClick={toggleAddExpenseModal}>
                                CANCEL
                            </button>
                        </div>
                    </div>
                </div>
            )}

            { /* Settle Balance Model*/}
            {isSettleBalanceModalOpen && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 backdrop-filter backdrop-blur-sm"
                    onClick={toggleSettleBalanceModal}
                >
                    <div className="bg-[#eae2f8] rounded-lg p-6 max-w-lg w-full relative" onClick={(e) => e.stopPropagation()}>
                        <h2 className="text-2xl font-semibold mb-4">Settle Balance</h2>
                        
                        <div>
                            <label className="block text-sm font-medium">Choose expense to settle</label>
                            <select className="mt-1 block w-full border border-gray-300 rounded-md">
                                <option>SELECT</option>
                                <option>R60 to member 1 for groceries</option>
                                <option>R150 to member 2 for transportation</option>
                            </select>
                        </div>

                        <br/>

                        <div>
                            <label className="block text-sm font-medium">Proof of payment</label>
                            <button className="mt-1 block w-full border border-gray-300 rounded-md px-4 py-2">OPEN FILE</button>
                        </div>

                        <br/>

                        {/* Modal Buttons */}
                        <div className="flex justify-end space-x-4">
                            <button className="px-4 py-2 bg-black text-white rounded-md">SAVE</button>
                            <button className="px-4 py-2 bg-gray-300 text-black rounded-md" onClick={toggleSettleBalanceModal}>
                                CANCEL
                            </button>
                        </div>

                    </div>
                </div>
            )}
        </div>
    );
};
