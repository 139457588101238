import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Menu } from "./Menu";

export const Header = () => {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const navigate = useNavigate();
    const username = localStorage.getItem('username');

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };

    const handleNavigation = (path) => {
        navigate(path);
        setIsNavOpen(false);
    };

    return (
        <div className="sticky top-0 z-50 bg-black bg-opacity-90 h-16 w-full flex items-center justify-between px-4 relative"> {/* Added 'sticky', 'top-0', and 'bg-opacity-80' */}
            {/* Menu Button */}
            <button onClick={toggleNav} className="text-white">
                <Menu />
            </button>

            {/* Profile Section */}
            <div className="flex items-center justify-end space-x-4 absolute right-8">
                <div onClick={() => handleNavigation('/profile')} className="text-white text-lg font-semibold cursor-pointer font-sans">
                    {username}
                </div>
                <img className="w-8 h-8 rounded-full cursor-pointer" src="/avatar.png" alt="Avatar" onClick={() => handleNavigation('/profile')} />
            </div>

            {/* Logo */}
            <img className="absolute left-1/2 transform -translate-x-1/2 h-12 object-contain cursor-pointer" alt="Logo" src="/YellowLogo.png" onClick={() => handleNavigation('/dashboard')}/>

            {/* Navigation Pane */}
            <div
                className={`fixed top-0 left-0 h-full w-64 bg-black bg-opacity-90 transform  ${isNavOpen ? "translate-x-0" : "-translate-x-full"} transition-transform duration-300 ease-in-out z-20`}
            >
                <div className="p-4">
                    {/* Close Button */}
                    <button onClick={toggleNav} className="mb-4">
                        <Menu />
                    </button>

                    {/* Navigation Links */}
                    <nav className="flex flex-col space-y-4 ">
                        <button onClick={() => handleNavigation('/dashboard')} className="text-white text-lg hover:bg-[#eae2f8] hover:text-black font-sans">DASHBOARD</button>
                        <button onClick={() => handleNavigation('/groups')} className="text-white text-lg hover:bg-[#eae2f8] hover:text-black font-sans">GROUPS</button>
                        <button onClick={() => handleNavigation('/expense-overview')} className="text-white text-lg hover:bg-[#eae2f8] hover:text-black font-sans">EXPENSES</button>
                    </nav>
                </div>

                {/* Sign Out */}
                <div className="absolute bottom-4 left-4">
                    <button onClick={() => handleNavigation('/')} className="flex items-center text-white text-lg hover:underline font-sans">
                        <span className="mr-2">↩️</span> sign out
                    </button>
                </div>
            </div>

            {/* Background overlay when the nav pane is open */}
            {isNavOpen && (
                <div
                    className="fixed inset-0 bg-transparent z-10"
                    onClick={toggleNav}
                ></div>
            )}
        </div>
    );
};

