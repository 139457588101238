import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Header} from "../components/Header"; // Use the existing Header component

export const Group = () => {
    const location = useLocation();
    const { groupName, groupId } = location.state;
    const navigate = useNavigate();

    // State to manage the group members, expenses, and modal visibility
    const [members, setMembers] = useState([]);
    const [newMemberEmail, setNewMemberEmail] = useState("");
    const [isAddMemberModalOpen, setAddMemberIsModalOpen] = useState(false);
    const [isExitGroupModalOpen, setExitGroupIsModalOpen] = useState(false);
    const [isEditGroupModalOpen, setEditGroupIsModalOpen] = useState(false);
    const [isAddExpenseModalOpen, setAddExpenseIsModalOpen] = useState(false);
    const [isDeleteGroupModalOpen, setDeleteGroupModalOpen] = useState(false);
    const [expensesDetails, setExpensesDetails] = useState([]);

    const [expenseType, setExpenseType] = useState("");
    const [expenseAmount, setExpenseAmount] = useState("");
    const [splitMethod, setSplitMethod] = useState("even");
    const [expenseDescription, setExpenseDescription] = useState("");
    const [isExpenseModalOpen, setIsExpenseModalOpen] = useState(false); // New state for the expense modal
    const [selectedExpense, setSelectedExpense] = useState(null); // Stores the selected expense
    const [isAddBalanceMode, setIsAddBalanceMode] = useState(false);
    const [isPayDebtMode, setIsPayDebtMode] = useState(false);

    const fetchExpensesDetails = async () => {
        try {
            const token = localStorage.getItem('idToken');

            // Fetch group expenses
            const groupExpensesResponse = await fetch(`http://localhost:8080/api/expenses/getGroupExpenses?groupId=${groupId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!groupExpensesResponse.ok) {
                console.error('Failed to fetch group expenses');
                return;
            }

            const groupExpensesData = await groupExpensesResponse.json();

            // Get details for each expense and calculate what the current user owes
            const expenseDetailsPromises = groupExpensesData.map(async (expense) => {
                const expenseDetailResponse = await fetch(`http://localhost:8080/api/expenses/getExpense?expenseId=${expense.expense_id}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (!expenseDetailResponse.ok) {
                    throw new Error(`Failed to fetch details for expense ID ${expense.expense_id}`);
                }

                return await expenseDetailResponse.json();
            });

            const allExpenseDetails = await Promise.all(expenseDetailsPromises);
            setExpensesDetails(allExpenseDetails); // Set the fetched details in state

        } catch (error) {
            console.error('Error fetching expense details:', error);
        }
    };

    // Call this function when the component mounts to fetch all expense details
    useEffect(() => {
        fetchGroupMembers();
        fetchExpensesDetails();
    }, [groupId]);

    const fetchGroupMembers = async () => {
        try {
            const token = localStorage.getItem('idToken');
            const response = await fetch(`http://localhost:8080/api/usergroups/group?groupId=${groupId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                console.error('Failed to fetch group members');
                return;
            }

            const membersData = await response.json();
            setMembers(membersData);
        } catch (error) {
            console.error('Error fetching members:', error);
        }
    };


    const handleDeleteGroup = async () => {
        try {
            const token = localStorage.getItem('idToken');
            const response = await fetch(`http://localhost:8080/api/groups/delete?id=${groupId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                const errorDetails = await response.text();
                console.error('Failed to delete group:', errorDetails);
                return;
            }

            console.log('Group deleted successfully');
            setDeleteGroupModalOpen(false);
            navigate('/dashboard');

        } catch (error) {
            console.error('Error deleting group:', error);
        }
    };

    const handleAddMember = async () => {
        try {
            const token = localStorage.getItem('idToken');
            const response = await fetch(`http://localhost:8080/api/usergroups/email?email=${newMemberEmail}&groupId=${groupId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                console.error('Failed to add member');
                return;
            }

            console.log('Member added successfully');
            setNewMemberEmail(""); // Clear the email field
            setAddMemberIsModalOpen(false); // Close the modal
            await fetchGroupMembers(); // Refresh members

        } catch (error) {
            console.error('Error adding member:', error);
        }
    };

    const handleSaveExpense = async () => {
        try {
            const token = localStorage.getItem('idToken');
            const userId = localStorage.getItem('id');

            if (!expenseType || !expenseAmount || !expenseDescription) {
                console.error('Please fill in all fields before saving.');
                return;
            }

            // Call the API to create the new expense
            const response = await fetch(
                `http://localhost:8080/api/expenses?name=${expenseDescription}&type=${expenseType}&group_id=${groupId}&owner_id=${userId}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }
            );

            if (!response.ok) {
                console.error('Failed to create expense');
                return;
            }

            console.log('Expense added successfully');
            const expensesData = await response.json();
            console.log(expensesData);

            // Call the API to add balance to the new expense
            const response_ = await fetch(
                `http://localhost:8080/api/userexpenses/addBalance?expense_id=${expensesData.expense_id}&amount=${expenseAmount}&splitMethod=${splitMethod}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }
            );

            if (!response_.ok) {
                console.error('Failed to add balance');
                return;
            }
            console.log('Balance added successfully');

            setAddExpenseIsModalOpen(false);
            setExpenseType("");
            setExpenseAmount("");
            setExpenseDescription("");

            await fetchExpensesDetails(); // Refresh expenses details

        } catch (error) {
            console.error('Error adding expense:', error);
        }
    };

    const handleAddBalance = async (amount) => {
        try {
            console.log("Adding balance for:", selectedExpense);
            console.log("Amount:", amount);
            const token = localStorage.getItem('idToken');

            if (amount === 0 || !selectedExpense || !selectedExpense.expense.expense_id) {
                alert("Invalid amount or expense.");
                return;
            }

            const response = await fetch(
                `http://localhost:8080/api/userexpenses/addBalance?expense_id=${selectedExpense.expense.expense_id}&amount=${amount}&splitMethod=even`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );

            if (!response.ok) {
                console.error('Failed to add balance to the expense');
                return;
            }

            console.log('Balance added successfully');
            closeExpenseModal(); // Close the modal
            await fetchExpensesDetails(); // Refresh expenses details
        } catch (error) {
            console.error('Error adding balance:', error);
        }
    };

    // Function to handle paying off debt for the selected expense
    const handlePayDebtForExpense = async (amount) => {
        try {
            const token = localStorage.getItem('idToken');
            const userId = localStorage.getItem('id');

            if (amount === 0) {
                alert("Invalid amount.");
                return;
            }

            const response = await fetch(
                `http://localhost:8080/api/userexpenses/payDebt?user_id=${userId}&expense_id=${selectedExpense.expense.expense_id}&amount=${amount}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );

            if (!response.ok) {
                console.error('Failed to pay debt for the expense');
                return;
            }

            console.log('Debt paid successfully');
            closeExpenseModal(); // Close the modal
            await fetchExpensesDetails(); // Refresh expenses details
        } catch (error) {
            console.error('Error paying debt:', error);
        }
    };

    // Function to handle deleting the selected expense
    const handleDeleteExpense = async () => {
        try {
            const token = localStorage.getItem('idToken');

            const response = await fetch(
                `http://localhost:8080/api/expenses/delete?id=${selectedExpense.expense.expense_id}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );

            if (!response.ok) {
                console.error('Failed to delete the expense');
                return;
            }

            console.log('Expense deleted successfully');
            closeExpenseModal(); // Close the modal
            await fetchExpensesDetails(); // Refresh expenses details
        } catch (error) {
            console.error('Error deleting expense:', error);
        }
    };

    const handleExpenseClick = (expense) => {
        console.log("Clicked Expense:", expense);  // Debugging statement
        setSelectedExpense(expense);
        setIsExpenseModalOpen(true);
    };

    // Modals Toggle Functions
    const toggleAddMemberModal = () => setAddMemberIsModalOpen(!isAddMemberModalOpen);
    const toggleExitGroupModal = () => setExitGroupIsModalOpen(!isExitGroupModalOpen);
    const toggleEditGroupModal = () => setEditGroupIsModalOpen(!isEditGroupModalOpen);
    const toggleAddExpenseModal = () => setAddExpenseIsModalOpen(!isAddExpenseModalOpen);
    const toggleDeleteGroupModal = () => setDeleteGroupModalOpen(!isDeleteGroupModalOpen);

    const closeExpenseModal = () => {
        setIsAddBalanceMode(false);
        setIsExpenseModalOpen(false);
    };

    const openAddBalanceModal = () => {
        setIsAddBalanceMode(true);
        setExpenseAmount(0);
    }

    const openPayDebtModal = () => {
        setIsPayDebtMode(true);
        setExpenseAmount(selectedExpense.user_expenses.amount_owed);
    }

    return (
        <div className="bg-[#f6f6f6] min-h-screen flex flex-col items-center">
            <Header className="w-full" />

            <div className="w-full max-w-screen-xl px-4 mt-8">
                <div className="w-full max-w-screen-lg mx-auto p-4 md:p-8">
                    <div className="text-center mb-8">
                        <h1 className="text-3xl font-bold">{groupName}</h1>
                        <p className="text-lg mt-2 font-sans">
                            {members.length > 0
                                ? members.map(member => member.username).join(", ")
                                : "No members available"}
                        </p>
                    </div>

                    <div className="flex justify-center space-x-4 mb-8">
                        <button className="px-6 py-2 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-[#eae2f8]  font-sans" onClick={toggleAddMemberModal}>
                            ADD MEMBER
                        </button>
                        <button className="px-6 py-2 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-[#eae2f8] font-sans" onClick={toggleExitGroupModal}>
                            EXIT GROUP
                        </button>
                        <button className="px-6 py-2 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-[#eae2f8] font-sans" onClick={toggleEditGroupModal}>
                            EDIT GROUP
                        </button>
                        <button className="px-6 py-2 bg-red-600 text-white border rounded-md shadow-sm hover:bg-red-500 font-sans" onClick={toggleDeleteGroupModal}>
                            DELETE GROUP
                        </button>
                    </div>

                    {/* Expenses List */}
                    <div className="bg-white rounded-xl shadow-lg p-4 md:p-6">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-2xl font-semibold">EXPENSES</h2>
                            <h2 className="text-2xl font-semibold">YOU OWE</h2>
                        </div>
                        <ul className="divide-y divide-gray-300">
                            {expensesDetails.map((expenseDetail) => (
                                <li key={expenseDetail.expense.expense_id} className="py-4 cursor-pointer" onClick={() => handleExpenseClick(expenseDetail)}>
                                    <div className="flex justify-between items-center">
                                        <div>
                                            <span
                                                className="text-lg font-bold">R{expenseDetail.total_owed}</span> for {expenseDetail.expense.type} ({expenseDetail.expense.name})
                                            <p className="text-sm">Created by: {expenseDetail.user_expenses.name}</p>
                                        </div>
                                        <span className="text-lg font-bold">R{expenseDetail.user_expenses.amount_owed}</span>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>

                    {/* Bottom Buttons */}
                    <div className="flex justify-center space-x-4 mt-8">
                        <button
                            className="px-6 py-3 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-[#eae2f8] font-sans" onClick={toggleAddExpenseModal}>
                            ADD EXPENSE
                        </button>
                    </div>
                </div>
            </div>

            {/* Expense Modal */}
            {isExpenseModalOpen && selectedExpense && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 backdrop-filter backdrop-blur-sm"
                    onClick={closeExpenseModal}
                >
                    <div className="bg-[#eae2f8] rounded-lg p-6 max-w-xl w-full relative" onClick={(e) => e.stopPropagation()}>
                        {isAddBalanceMode ? (
                            // Add Balance UI
                            <>
                                <h2 className="text-3xl font-semibold mb-6">Add Balance</h2>
                                <div className="flex flex-col items-center mb-6">
                                    <p className="text-xl font-bold mb-4">Total Amount: R{selectedExpense.total_owed + expenseAmount}</p>
                                    <div className="flex items-center space-x-6 mt-4">
                                        <button
                                            className=" text-black text-6xl font-bold "
                                            onClick={() => setExpenseAmount((prev) => Math.max(0, prev - 1))}
                                        >
                                            -
                                        </button>
                                        <input
                                            value={expenseAmount}
                                            onChange={(e) => setExpenseAmount(parseFloat(e.target.value) || 0)} // Allow typing in the value
                                            className="w-20 text-center text-3xl font-bold rounded-md bg-[#eae2f8]"
                                        />
                                        <button
                                            className="text-black text-6xl font-bold "
                                            onClick={() => {
                                                setExpenseAmount((prev) => {
                                                    return prev + 1;
                                                });
                                            }}
                                        >
                                            +
                                        </button>
                                    </div>
                                </div>
                                {/* Modal Buttons */}
                                <div className="flex justify-end space-x-4">
                                    <button
                                        className="px-4 py-2 bg-black text-white text-lg font-bold rounded-md font-sans"
                                        onClick={async () => {
                                            await handleAddBalance(expenseAmount);
                                            setIsAddBalanceMode(false);
                                            closeExpenseModal(); // Save and close
                                        }}
                                    >
                                        SAVE
                                    </button>
                                    <button
                                        className="px-4 py-2 bg-gray-300 text-black text-lg font-bold rounded-md font-sans"
                                        onClick={() => {
                                            setIsAddBalanceMode(false); // Reset the balance mode
                                            closeExpenseModal(); // Cancel and close
                                        }}
                                    >
                                        CANCEL
                                    </button>
                                </div>
                            </>
                        ) : isPayDebtMode ? (
                                    // Add Balance UI
                                    <>
                                        <h2 className="text-3xl font-semibold mb-6">PayDebt</h2>
                                        <div className="flex flex-col items-center mb-6">
                                            <p className="text-xl font-bold mb-4">Amount Owing after Payment: R{selectedExpense.user_expenses.amount_owed - expenseAmount}</p>
                                            <div className="flex items-center space-x-6 mt-4">
                                                <button
                                                    className=" text-black text-6xl font-bold "
                                                    onClick={() => setExpenseAmount((prev) => Math.max(0, prev - 1))}
                                                >
                                                    -
                                                </button>
                                                <input
                                                    value={expenseAmount}
                                                    onChange={(e) => setExpenseAmount(parseFloat(e.target.value) || 0)} // Allow typing in the value
                                                    className="w-20 text-center text-3xl font-bold rounded-md bg-[#eae2f8]"
                                                />
                                                <button
                                                    className="text-black text-6xl font-bold "
                                                    onClick={() => {
                                                        setExpenseAmount((prev) => {
                                                            return Math.min(selectedExpense.user_expenses.amount_owed, prev + 1);
                                                        });
                                                    }}
                                                >
                                                    +
                                                </button>
                                            </div>
                                        </div>
                                        {/* Modal Buttons */}
                                        <div className="flex justify-end space-x-4">
                                            <button
                                                className="px-4 py-2 bg-black text-white text-lg font-bold rounded-md font-sans"
                                                onClick={async () => {
                                                    await handlePayDebtForExpense(expenseAmount);
                                                    setIsPayDebtMode(false);
                                                    closeExpenseModal(); // Save and close
                                                }}
                                            >
                                                SAVE
                                            </button>
                                            <button
                                                className="px-4 py-2 bg-gray-300 text-black text-lg font-bold rounded-md font-sans"
                                                onClick={() => {
                                                    setIsPayDebtMode(false); // Reset the balance mode
                                                    closeExpenseModal(); // Cancel and close
                                                }}
                                            >
                                                CANCEL
                                            </button>
                                        </div>
                                    </>
                                ) :(
                            // Default Manage Expense UI
                            <>
                                <h2 className="text-2xl font-semibold mb-4">Manage Expense</h2>
                                <div>
                                    <p className="text-lg font-bold">Expense: {selectedExpense.expense.name}</p>
                                    <p className="text-sm">Type: {selectedExpense.expense.type}</p>
                                    <p className="text-sm">Total Amount: R{selectedExpense.total_owed}</p>
                                    <p className="text-sm">Amount Owed by You: R{selectedExpense.user_expenses.amount_owed}</p>
                                </div>

                                {/* Modal Buttons */}
                                <div className="flex justify-end space-x-4 mt-6">
                                    <button className="px-4 py-2 bg-white text-black rounded-md font-sans"
                                            onClick={openAddBalanceModal}>
                                        ADD BALANCE
                                    </button>
                                    <button className="px-4 py-2 bg-white text-black rounded-md font-sans"
                                            onClick={openPayDebtModal}>
                                        PAY DEBT
                                    </button>
                                    <button className="px-4 py-2 bg-red-600 text-white rounded-md font-sans"
                                            onClick={handleDeleteExpense}>
                                        DELETE
                                    </button>
                                    <button className="px-4 py-2 bg-gray-300 text-black rounded-md font-sans"
                                            onClick={closeExpenseModal}>
                                        CANCEL
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}

            {/* Add Expense Modal */}
            {isAddExpenseModalOpen && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 backdrop-filter backdrop-blur-sm"
                    onClick={toggleAddExpenseModal}
                >
                    <div className="bg-[#eae2f8] rounded-lg p-6 max-w-lg w-full relative" onClick={(e) => e.stopPropagation()}>
                        <h2 className="text-2xl font-semibold mb-4">Add Expense</h2>
                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <label className="block text-sm font-medium font-sans">Type *</label>
                                <select
                                    value={expenseType}
                                    onChange={(e) => setExpenseType(e.target.value)}
                                    className="mt-1 block w-full border border-gray-300 rounded-md"
                                >
                                    <option value="">SELECT</option>
                                    <option value="Food">Food</option>
                                    <option value="Transport">Transport</option>
                                    <option value="Rent">Rent</option>
                                    <option value="Utilities">Utilities</option>
                                    <option value="Groceries">Groceries</option>
                                    <option value="Travel">Travel</option>
                                    <option value="Dining Out">Dining Out</option>
                                    <option value="Entertainment">Entertainment</option>
                                    <option value="Transportation">Transportation</option>
                                    <option value="Household Supplies">Household Supplies</option>
                                    <option value="Internet">Internet</option>
                                    <option value="Petrol">Petrol</option>
                                    <option value="Insurance">Insurance</option>
                                    <option value="Repairs">Repairs</option>
                                    <option value="Gifts">Gifts</option>
                                    <option value="Sundry">Sundry</option>

                                    {/* Add other options here as needed */}
                                </select>
                            </div>
                            <div>
                                <label className="block text-sm font-medium font-sans">Amount *</label>
                                <input
                                    type="number"
                                    value={expenseAmount}
                                    onChange={(e) => setExpenseAmount(e.target.value)}
                                    className="mt-1 block w-full border border-gray-300 rounded-md"
                                    placeholder="R"
                                />
                            </div>

                            <div>
                                <label className="block text-sm font-medium font-sans">Split method *</label>
                                <select className="mt-1 block w-full border border-gray-300 rounded-md" onChange={(e) => setSplitMethod(e.target.value)}>
                                    <option value="">SELECT</option>
                                    <option value={splitMethod}>Equal Split</option>
                                    <option value="custom">Custom Split</option>
                                </select>
                            </div>

                            <div className="col-span-2">
                                <label className="block text-sm font-medium font-sans">Description *</label>
                                <textarea
                                    value={expenseDescription}
                                    onChange={(e) => setExpenseDescription(e.target.value)}
                                    className="mt-1 block w-full border border-gray-300 rounded-md"
                                    placeholder="Description"
                                />
                            </div>
                        </div>
                        {/* Modal Buttons */}
                        <div className="flex justify-end space-x-4 mt-4">
                            <button
                                className="px-4 py-2 bg-black text-white rounded-md font-sans"
                                onClick={handleSaveExpense} // Call the function that handles saving the expense
                            >
                                SAVE
                            </button>
                            <button className="px-4 py-2 bg-gray-300 text-black rounded-md font-sans" onClick={toggleAddExpenseModal}>
                                CANCEL
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Delete Group Modal */}
            {isDeleteGroupModalOpen && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 backdrop-filter backdrop-blur-sm"
                    onClick={toggleDeleteGroupModal}
                >
                    <div className="bg-[#eae2f8] rounded-lg p-6 max-w-lg w-full relative" onClick={(e) => e.stopPropagation()}>
                        <label className="block text-sm font-medium font-sans">Are you sure you want to delete this group?</label>
                        <br/>

                        {/* Modal Buttons */}
                        <div className="flex justify-end space-x-4">
                            <button
                                className="px-4 py-2 bg-red-600 text-white rounded-md font-sans"
                                onClick={handleDeleteGroup} // Handle the group deletion
                            >
                                DELETE GROUP
                            </button>
                            <button className="px-4 py-2 bg-gray-300 text-black rounded-md font-sans" onClick={toggleDeleteGroupModal}>
                                CANCEL
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Add Member Modal */}
            {isAddMemberModalOpen && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 backdrop-filter backdrop-blur-sm"
                    onClick={toggleAddMemberModal}
                >
                    <div className="bg-[#eae2f8] rounded-lg p-6 max-w-lg w-full relative" onClick={(e) => e.stopPropagation()}>
                        <label className="block text-sm font-medium font-sans">Add anyone to join your group using their email:</label>
                        <br/>
                        <input
                            type="text"
                            value={newMemberEmail}
                            onChange={(e) => setNewMemberEmail(e.target.value)} // Capture the email input
                            className="mt-1 block w-full border border-gray-300 rounded-md"
                            placeholder="example@gmail.com"
                        />
                        <br/>

                        {/* Modal Buttons */}
                        <div className="flex justify-end space-x-4">
                            <button
                                className="px-4 py-2 bg-black text-white rounded-md font-sans"
                                onClick={handleAddMember} // Handle the member addition
                            >
                                ADD TO GROUP
                            </button>
                            <button className="px-4 py-2 bg-gray-300 text-black rounded-md font-sans" onClick={toggleAddMemberModal}>
                                CANCEL
                            </button>
                        </div>
                    </div>
                </div>
            )}

        </div>


    );
};