    import React, { useEffect, useState } from "react";
    import { useNavigate } from "react-router-dom";
    import { Header } from "../components/Header";
    import { AiOutlinePlus } from "react-icons/ai"; // Import the Plus Icon
    import { CreateGroup } from "../components/CreateGroup"; // Import the modal component
    import '../App.css';

    export const Dashboard = () => {
        const navigate = useNavigate();
        const [groups, setGroups] = useState([]);
        const [loading, setLoading] = useState(true);
        const [error, setError] = useState(null);
        const [selectedGroupMembers, setSelectedGroupMembers] = useState({});
        const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
        const [totalUserOwes, setTotalUserOwes] = useState(0); // For "YOU CURRENTLY OWE"
        const [totalUserIsOwed, setTotalUserIsOwed] = useState(0); // For "YOU ARE OWED"

        useEffect(() => {
            const fetchGroupsAndMembers = async () => {
                try {
                    setLoading(true);

                    const token = localStorage.getItem('idToken');
                    const userId = localStorage.getItem('id');

                    console.log('Fetching groups for user with ID:', userId);
                    console.log('Token:', token);

                    // Fetch the total amount owed
                    const debtResponse = await fetch(`http://localhost:8080/api/expenses/getNetDebt?userId=${userId}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        }
                    });

                    if (debtResponse.ok) {
                        const debtAmount = await debtResponse.json();
                        setTotalUserOwes(debtAmount);
                    }

                    // Fetch the total amount owed to user
                    const owedResponse = await fetch(`http://localhost:8080/api/expenses/getNetOwed?userId=${userId}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        }
                    });

                    if (owedResponse.ok) {
                        const owedAmount = await owedResponse.json();
                        setTotalUserIsOwed(owedAmount);
                    }

                    const groupResponse = await fetch(`http://localhost:8080/api/usergroups/user?userId=${userId}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        }
                    });

                    if (!groupResponse.ok) {
                        throw new Error('Failed to fetch groups');
                    }

                    console.log('Fetched groups successfully');
                    const groupsData1 = await groupResponse.json();
                    const groupsData = groupsData1.reverse(); // Reverse the order
                    const limitedGroups = groupsData.slice(0, 3); // Limit to 3 groups
                    setGroups(limitedGroups);

                    console.log('Fetching group members');

                    // Fetch group members and debts for the limited groups
                    const groupMembersPromises = limitedGroups.map(async (group) => {
                        const membersResponse = await fetch(`http://localhost:8080/api/usergroups/group?groupId=${group.id}`, {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`
                            }
                        });

                        if (!membersResponse.ok) {
                            throw new Error(`Failed to fetch members for group ${group.group_name}`);
                        }

                        const members = await membersResponse.json();
                        return { groupId: group.id, members: members.map(member => member.username) };
                    });

                    const groupMembersData = await Promise.all(groupMembersPromises);
                    console.log('Fetched group members successfully');

                    const membersMap = {};
                    groupMembersData.forEach(({ groupId, members }) => {
                        membersMap[groupId] = members;
                    });

                    setSelectedGroupMembers(membersMap);

                } catch (error) {
                    console.error('An error occurred:', error.message);
                    setError(error.message);
                } finally {
                    setLoading(false);
                }

            };

            fetchGroupsAndMembers();
        }, []);


        const handleExpenseOverviewClick = () => {
            navigate("/expense-overview");
        };

        const handleGroupClick = (groupName, groupId) => {
            navigate(`/group/${groupName}`, { state: { groupName, groupId } });
        };

        const handleNavigation = (path) => {
            navigate(path);
        };

        const handleAddGroupClick = () => {
            setIsModalOpen(true);  // Open modal on click
        };

        const handleCreateGroup = (newGroup) => {
            // Add the new group to the state
            setGroups((prevGroups) => [...prevGroups, newGroup].slice(0, 3)); // Add the new group but still limit to 3
        };

        if (loading) {
            return <div>Loading...</div>;
        }

        if (error) {
            return <div>Error: {error}</div>;
        }

        return (
            <div className="bg-gray-100 min-h-screen flex flex-col items-center">
                <Header className="w-full" />

                <div className="w-full max-w-screen-xl px-4 mt-8">
                    {/* Expense Overview Section */}
                    <div className="text-center rounded-xl overflow-hidden bg-white shadow-lg p-6 md:p-8">
                        <div className="flex flex-col md:flex-row justify-between items-center space-y-8 md:space-y-0">
                            <div className="text-center">
                                <div
                                    className="text-xl md:text-2xl ml-4 md:ml-8 lg:ml-40"
                                >YOU CURRENTLY OWE:</div>
                                <br />
                                <div className="text-red-600 text-5xl md:text-[100px] font-mono ml-4 md:ml-8 lg:ml-40">R{totalUserOwes}</div>
                            </div>
                            <div className="text-center">
                                <div
                                    className="text-xl md:text-2xl mr-4 md:mr-8 lg:mr-40"
                                >YOU ARE OWED:</div>
                                <br />
                                <div className="text-green-600 text-5xl md:text-[100px] font-mono mr-4 md:mr-8 lg:mr-40">R{totalUserIsOwed}</div>
                            </div>
                        </div>
                        <button
                            className="px-8 py-3 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-[#eae2f8] text-lg font-sans"
                            onClick={handleExpenseOverviewClick}
                        >
                            view details
                        </button>
                    </div>

                    {/* Groups Section */}
                    <div className="text-center mt-8 p-4 md:p-8">
                        <div className="text-3xl md:text-4xl font-girassol text-center mb-8">GROUPS</div>
                        <div className="grid grid-cols-3 gap-6">
                            {/* Render group containers */}
                            {groups.map((group) => (
                                <div
                                    key={group.id}
                                    className="bg-gray-300 rounded-[40px] p-4 md:p-6 shadow-lg w-full cursor-pointer min-h-[500px] md:min-h-[350px]"
                                    onClick={() => handleGroupClick(group.group_name, group.id)} // Pass group name and ID
                                >
                                    <div className="text-xl md:text-2xl font-girassol text-center">{group.group_name}</div>
                                    <p className="text-sm text-center mt-2 font-sans">
                                        {selectedGroupMembers[group.id]
                                            ? selectedGroupMembers[group.id].join(", ")
                                            : "Loading members..."}
                                    </p>
                                </div>
                            ))}

                            {/* Show the "+" icon for adding a group based on group count */}
                            {groups.length < 3 && (
                                <div
                                    className="bg-gray-200 rounded-[40px] p-4 md:p-6 shadow-lg w-full cursor-pointer min-h-[500px] md:min-h-[350px] flex items-center justify-center"
                                    onClick={handleAddGroupClick}
                                >
                                    <AiOutlinePlus className="text-5xl text-gray-500" />
                                </div>
                            )}
                        </div>
                        <br />
                        <button
                            className="px-8 py-3 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-[#eae2f8] text-lg font-sans"
                            onClick={() => handleNavigation('/groups')}
                        >
                            view more
                        </button>
                    </div>
                </div>

                {/* Modal for creating a new group */}
                {isModalOpen && (
                    <CreateGroup
                        onClose={() => setIsModalOpen(false)}
                        onCreateGroup={handleCreateGroup}
                    />
                )}
            </div>
        );
    };
