import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Header } from "../components/Header";
import { CreateGroup } from "../components/CreateGroup"; // Import the modal component

export const Groups = () => {
    const navigate = useNavigate();
    const [groups, setGroups] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedGroupMembers, setSelectedGroupMembers] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Fetch groups and members from the backend
    const fetchGroupsAndMembers = async () => {
        try {
            setLoading(true);

            const token = localStorage.getItem('idToken');
            const userId = localStorage.getItem('id');

            // Fetch user-specific groups
            const groupResponse = await fetch(`http://localhost:8080/api/usergroups/user?userId=${userId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!groupResponse.ok) {
                throw new Error('Failed to fetch groups');
            }

            const groupsData = await groupResponse.json();
            setGroups(groupsData.reverse());  // Reverse order to show latest first

            const groupMembersPromises = groupsData.map(async (group) => {
                const membersResponse = await fetch(`http://localhost:8080/api/usergroups/group?groupId=${group.id}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (!membersResponse.ok) {
                    throw new Error(`Failed to fetch members for group ${group.group_name}`);
                }

                const members = await membersResponse.json();
                return { groupId: group.id, members: members.map(member => member.username) };
            });

            const groupMembersData = await Promise.all(groupMembersPromises);

            const membersMap = {};
            groupMembersData.forEach(({ groupId, members }) => {
                membersMap[groupId] = members;
            });

            setSelectedGroupMembers(membersMap);

        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchGroupsAndMembers();
    }, []);

    // Navigate to the group details page
    const handleGroupClick = (groupName, groupId) => {
        navigate(`/group/${groupName}`, { state: { groupName, groupId } });
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="bg-white flex flex-col items-center min-h-screen">
            <Header className="w-full" />

            {/* Groups Section */}
            <div className="flex flex-col items-center w-full max-w-screen-lg mt-12 px-4">
                <div className="text-4xl font-girassol mb-8">GROUPS</div>

                {/* Carousel Wrapper */}
                <div className="overflow-x-auto w-full rounded-[40px]">
                    <div className="flex space-x-6" style={{ minWidth: '100%' }}>
                        {groups.map((group, index) => (
                            <div
                                key={index}
                                className="bg-gray-300 rounded-[40px] p-4 md:p-6 shadow-lg w-[30%] flex-none cursor-pointer min-h-[500px] md:min-h-[350px]"
                                onClick={() => handleGroupClick(group.group_name, group.id)} // Passing group name here
                            >
                                <div className="text-xl md:text-2xl font-girassol text-center">{group.group_name}</div>
                                <p className="text-sm text-center mt-2 font-sans">
                                    {selectedGroupMembers[group.id]
                                        ? selectedGroupMembers[group.id].join(", ")
                                        : "No members"}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Create New Group Button */}
                <button
                    className="px-6 py-3 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-[#eae2f8] mt-6 font-sans"
                    onClick={() => setIsModalOpen(true)}
                >
                    CREATE NEW GROUP
                </button>

                {isModalOpen && (
                    <CreateGroup
                        onClose={() => setIsModalOpen(false)}
                        onCreateGroup={fetchGroupsAndMembers}
                    />
                )}
            </div>
        </div>
    );
};
