import React, { useState }  from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "../components/Header";

export const Profile = () => {
    const token = localStorage.getItem('idToken');
    const username = localStorage.getItem('username');
    const userID = localStorage.getItem('id');
    const email = localStorage.getItem('email');

    const navigate = useNavigate();
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [usernameText, setUsernameText] = useState(username);
    const [emailText, setEmailText] = useState(email);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);


    // Handle changes in the text field
    const handleUsernameTextChange = (e) => {
        setUsernameText(e.target.value);
    };

    // Handle changes in the text field
    const handleEmailTextChange = (e) => {
        setEmailText(e.target.value);
    };

    // Function to toggle the editProfileModal
    const toggleEditProfileModal = () => {
        setIsEditModalOpen(!isEditModalOpen);
    };

    const toggleDeleteProfileModal = () => {
        setIsDeleteModalOpen(!isDeleteModalOpen);
    };

    const handleSignOutClick = () => {
        navigate("/");
    };


    const handleProfileUpdate = async () => {
        try {
            setLoading(true);

            
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteProfile = async () => {
        try {
            setLoading(true);

            const response = await fetch(`http://localhost:8080/api/users/delete?id=${userID}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error("Failed to delete user");
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
            handleSignOutClick();
        }
    };

    const handleSignOut = async () => {
        try {
            setLoading(true);

            const response = await fetch(`http://localhost:8080/api/auth/logout`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ token: token }),
            })

            if (!response.ok) {
                throw new Error("Failed to sign out");
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
            handleSignOutClick();
        }
    }

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="bg-[#f6f6f6] flex flex-col items-center w-full min-h-screen">
            {/* Header */}
            <Header className="w-full" />

            {/* Profile Section */}
            <div className="flex flex-col items-center mt-16">
                {/* Avatar */}
                <div className="w-40 h-40 bg-purple-100 rounded-full flex items-center justify-center mb-8">
                    <img src="/avatar.png" alt="Avatar" className="w-20 h-20" />
                </div>

                {/* Name */}
                <div className="text-3xl font-semibold text-gray-800 mb-10">
                    {username}
                </div>

                {/* Buttons */}
                <div className="grid grid-cols-2 gap-6 mb-10">
                    <button 
                        className="px-8 py-3 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-[#eae2f8] text-lg font-sans"
                        onClick={toggleEditProfileModal}
                    >

                        EDIT PROFILE
                    </button>
                    <button 
                        className="px-8 py-3 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-[#eae2f8] text-lg font-sans"
                        onClick={handleSignOut}
                    >
                        SIGN OUT
                    </button>
                    <button 
                        className="col-span-2 px-8 py-3 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-[#eae2f8] text-lg font-sans"
                        onClick={toggleDeleteProfileModal}
                    >
                        DELETE PROFILE
                    </button>
                </div>
            </div>

            {isEditModalOpen && (
                <div
                className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 backdrop-filter backdrop-blur-sm"
                onClick={toggleEditProfileModal}
                >
                    <div className="bg-[#eae2f8] rounded-lg p-6 max-w-lg w-full relative" onClick={(e) => e.stopPropagation()}>
                        <h2 className="text-2xl font-semibold mb-4">Edit Profile</h2>
                        <div className="grid grid-cols-1 gap-4 mb-4">
                            <div>
                                <label className="block text-sm font-medium font-sans">Profile Picture</label>
                                <div className="grid grid-cols-2 gap-4 mb-4 place-items-center">
                                    <img className="w-16 h-16 md:w-24 md:h-24 lg:w-32 lg:h-32 rounded-full cursor-pointer" src="/avatar.png" alt="Avatar"/>
                                    <button className="mt-1 block w-1/2 h-1/2 border border-gray-300 hover:bg-[#eae2f8] rounded-md px-4 py-2 font-sans">CHANGE</button>
                                </div>
                            </div>
                            <div>
                                <label className="block text-sm font-medium font-sans">Username</label>
                                <input 
                                    type="text" 
                                    value={usernameText}
                                    onChange={handleUsernameTextChange} 
                                    className="mt-1 block w-full border border-gray-300 rounded-md" 
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium font-sans">Email Address</label>
                                <input 
                                    type="text"
                                    value={emailText}
                                    onChange={handleEmailTextChange} 
                                    className="mt-1 block w-full border border-gray-300 rounded-md"                                   
                                />
                            </div>
                        </div>

                        {/* Modal Buttons */}
                        <div className="flex justify-end space-x-4">
                            <button 
                                className="px-4 py-2 bg-black text-white rounded-md font-sans" 
                                onClick={() => {
                                    toggleEditProfileModal();
                                    handleProfileUpdate();
                                }}
                            >
                                SAVE
                            </button>
                            <button 
                                className="px-4 py-2 bg-gray-300 text-black rounded-md font-sans" 
                                onClick={() => {
                                    toggleEditProfileModal();
                                    setUsernameText(username);
                                    setEmailText(email);
                                }}
                            >
                                CANCEL
                            </button>
                        </div>

                    </div>
                </div>
            )}

            {isDeleteModalOpen && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 backdrop-filter backdrop-blur-sm"
                    onClick={toggleEditProfileModal}
                >
                    <div className="bg-[#eae2f8] rounded-lg p-6 max-w-lg w-full relative" onClick={(e) => e.stopPropagation()}>
                        <label className="block text-sm font-medium font-sans">Are you sure you want to delete your profile?</label>
                        <div className="flex justify-end space-x-4">
                            <button className="px-4 py-2 bg-gray-300 text-black rounded-md font-sans" 
                                onClick={handleDeleteProfile}
                            >
                               YES 
                            </button>
                            <button className="px-4 py-2 bg-black text-white rounded-md font-sans" onClick={toggleDeleteProfileModal}>
                                NO
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Profile;
