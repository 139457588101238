import React, { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";

export const CreateGroup = ({ onClose }) => {
    const [groupName, setGroupName] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleCreateGroup = async (e) => {
        e.preventDefault();
        setLoading(true);

        const token = localStorage.getItem("idToken");
        const userId = localStorage.getItem("id");

        try {
            // Create a new group
            const response = await fetch("http://localhost:8080/api/groups", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ group_name: groupName }),
            });

            if (!response.ok) {
                throw new Error("Failed to create group");
            }

            const groupData = await response.json(); // Group creation response

            // Automatically add the current user to the newly created group
            const addUserResponse = await fetch(
                `http://localhost:8080/api/usergroups?userId=${userId}&groupId=${groupData.id}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (!addUserResponse.ok) {
                throw new Error("Failed to add user to the group");
            }

            // Success - Close the modal, the parent component will handle refetching
            onClose(); // Close modal after success
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
                <h2 className="text-2xl font-semibold mb-6">Create New Group</h2>

                {error && <div className="text-red-500 mb-4">{error}</div>}

                <form onSubmit={handleCreateGroup}>
                    <div className="mb-4">
                        <label className="block text-gray-700 mb-2">Group Name</label>
                        <input
                            type="text"
                            className="w-full px-4 py-2 border rounded-md"
                            value={groupName}
                            onChange={(e) => setGroupName(e.target.value)}
                            required
                        />
                    </div>

                    <div className="flex justify-end">
                        <button
                            type="submit"
                            className="px-4 py-2 bg-gray-500 text-white rounded-md shadow"
                            disabled={loading}
                        >
                            {loading ? "Creating..." : "Create Group"}
                        </button>
                        <button
                            type="button"
                            className="ml-2 px-4 py-2 bg-gray-300 rounded-md"
                            onClick={onClose}
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};
