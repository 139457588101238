import React from "react";
import { useNavigate } from 'react-router-dom';

export const FirstPage = () => {
    const navigate = useNavigate();

    return (
        <div className="relative flex flex-row w-full h-screen">
            {/* Logo in the middle */}
            <img
                className="absolute w-full max-w-lg left-1/2 transform -translate-x-1/2 top-1/4 -translate-y-1/2 z-50"
                alt="Logo"
                src="/YellowLogo.png"
            />

            {/* Left Section - Title and Login */}
            <div className="flex-1 bg-black flex flex-col items-center justify-center p-8 pr-4 relative">
                {/* Text on top */}
                <h2 className="text-7xl  text-[#f5f4b8] font-bold mt-[70px] mr-5 ml-auto">manage your</h2>

                {/* Left Button - Login */}
                <button
                    className="px-12 py-3 bg-white text-black rounded-md shadow hover:bg-[#eae2f8] absolute bottom-1/4 font-sans"
                    style={{ right: '5%' }} // Bring it closer to the middle
                    onClick={() => navigate('/login')}
                >
                    LOGIN
                </button>
            </div>

            {/* Right Section - Title and Sign Up */}
            <div className="flex-1 bg-white flex flex-col justify-center items-center p-8 pl-2 relative">
                {/* Text on top */}
                <h2 className="text-8xl font-heading text-black font-extrabold ml-3 mt-[100px] mr-auto">GROUP EXPENSES</h2>
                <h4 className="text-md font-[sans-serif] text-gray mt-1 ml-5 mr-auto">all in one place</h4>

                {/* Right Button - Sign Up */}
                <button
                    className="px-12 py-3 bg-black text-white rounded-md shadow hover:bg-[#eae2f8] hover:text-black absolute bottom-1/4 font-sans"
                    style={{ left: '5%' }} // Bring it closer to the middle
                    onClick={() => navigate('/signup')}
                >
                    SIGN UP
                </button>
            </div>
        </div>
    );
};
